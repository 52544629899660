import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
  name: 'secondary-small-button'
})
export default class SecondarySmallButton extends Vue {

  handleClick() {
    this.$emit('click');
  }
}
